import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import './IssuesPage.css';
import './Comments.css';
import { Helmet } from 'react-helmet';
import RelatedServices from './RelatedServices';
import {
  CategoryScale,
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function IssuesPage() {
  const { dienstName } = useParams();
  const [serviceInfo, setServiceInfo] = useState(null);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [feedback, setFeedback] = useState({ show: false, message: '', success: false });
  const [timeRange, setTimeRange] = useState('24hours');
  const [commentName, setCommentName] = useState('');
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const seoTitle = `Störungsmeldung für ${serviceInfo ? serviceInfo.Titel : dienstName}`;
  const seoDescription = `Aktuelle Informationen und Status zu Störungen bei ${serviceInfo ? serviceInfo.Titel : dienstName}. Melden Sie hier Störungen und sehen Sie vorhandene Meldungen.`;

  const handleNameChange = (event) => setCommentName(event.target.value);
  const handleTextChange = (event) => setCommentText(event.target.value);

  useEffect(() => {
    axios.get(`https://disapi.armann-systems.com/search-services`, { params: { term: dienstName }})
      .then(response => {
        if (response.data.length > 0) {
          setServiceInfo(response.data[0]);
          fetchReportData(response.data[0].DienstID, timeRange);
        } else {
          setFeedback({ show: true, message: 'Dienst nicht gefunden', success: false });
        }
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Dienstinformationen:', error);
        setFeedback({ show: true, message: 'Fehler beim Laden der Dienstinformationen', success: false });
      });
  }, [dienstName, timeRange]);

  const formatDate = (date) => {
    const roundedDate = new Date(date);
    roundedDate.setMinutes(0);
    roundedDate.setSeconds(0);
    return roundedDate.toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).replace(/(\d+:\d+):\d+/, '$1');
  };

  const transformData = (reports, range) => {
    let intervals, intervalLengthInHours;
    if (range === '7days') {
      intervals = 7 * 8; // 8 intervals per day for 7 days
      intervalLengthInHours = 3;
    } else {
      intervals = 24;
      intervalLengthInHours = 1;
    }

    const now = new Date();
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    const reportCounts = Array(intervals).fill(0);
    const labels = Array(intervals).fill('');
    const timestamps = Array(intervals).fill(null);

    for (let i = 0; i < intervals; i++) {
      const intervalDate = new Date(now.getTime() - (intervals - i - 1) * intervalLengthInHours * 60 * 60 * 1000);
      timestamps[i] = intervalDate;

      if (range === '7days') {
        if (i % 8 === 0) {
          labels[i] = intervalDate.toLocaleString('de-DE', {
            day: '2-digit',
            month: '2-digit'
          });
        } else {
          labels[i] = intervalDate.getHours().toString().padStart(2, '0') + ':00';
        }
      } else {
        labels[i] = intervalDate.getHours().toString().padStart(2, '0') + ':00';
      }
    }

    reports.forEach(report => {
      const reportDate = new Date(report.Timestamp);
      const diffHours = Math.floor((now - reportDate) / (1000 * 60 * 60));
      const index = intervals - Math.floor(diffHours / intervalLengthInHours) - 1;
      if (index >= 0 && index < intervals) {
        reportCounts[index]++;
      }
    });

    return { labels, reportCounts, timestamps };
  };

  const fetchReportData = (dienstID, range) => {
    axios.get(`https://disapi.armann-systems.com/reports`, { params: { dienstID, limitToLastHour: 'false' } })
      .then(response => {
        const transformedData = transformData(response.data, range);
        setChartData({
          labels: transformedData.labels,
          timestamps: transformedData.timestamps,
          datasets: [{
            label: 'Anzahl der Störungen',
            data: transformedData.reportCounts,
            fill: true,
            backgroundColor: 'rgba(210, 24, 48, 0.1)',
            borderColor: '#d21830',
            tension: 0.4,
            pointRadius: 4,
            pointBackgroundColor: '#d21830',
            pointBorderColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: '#d21830',
            pointHoverBorderColor: '#fff',
            pointHoverBorderWidth: 2,
            borderWidth: 3,
          }],
        });
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Reports:', error);
      });
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.05)',
          drawBorder: false,
        },
        ticks: {
          stepSize: 1,
          callback: function(value) {
            if (Number.isInteger(value)) {
              return value;
            }
          },
          font: {
            size: 12,
            weight: '500',
          },
          color: '#666',
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 11,
            weight: '500',
          },
          color: '#666',
          maxRotation: 0,
          callback: function(val, index) {
            const label = this.getLabelForValue(val);
            if (timeRange === '7days') {
              return index % 8 === 0 ? label : '';
            } else {
              return window.innerWidth < 768 ? 
                (index % 6 === 0 ? label : '') :
                (index % 4 === 0 ? label : '');
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#2c2c2c',
        bodyColor: '#2c2c2c',
        titleFont: {
          size: 14,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        padding: 12,
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        displayColors: false,
        callbacks: {
          title: function(tooltipItems) {
            const timestamp = chartData.timestamps[tooltipItems[0].dataIndex];
            if (!timestamp) return '';
            const roundedTimestamp = new Date(timestamp);
            roundedTimestamp.setMinutes(0);
            roundedTimestamp.setSeconds(0);
            if (timeRange === '7days') {
              return `Zeitpunkt: ${formatDate(roundedTimestamp)}`;
            }
            return `Zeitpunkt: ${roundedTimestamp.getHours().toString().padStart(2, '0')}:00`;
          },
          label: function(context) {
            return `Störungen: ${context.raw}`;
          },
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  };

  const submitComment = () => {
    if (!commentName || !commentText) {
      setFeedback({ 
        show: true, 
        message: 'Bitte füllen Sie sowohl den Namen als auch den Kommentar aus.', 
        success: false 
      });
      return;
    }
  
    axios.post('https://disapi.armann-systems.com/add-comment', {
      dienstTitel: serviceInfo.Titel,
      name: commentName,
      text: commentText
    })
    .then(() => {
      setFeedback({ 
        show: true, 
        message: 'Kommentar wurde erfolgreich gesendet', 
        success: true 
      });
      setCommentName('');
      setCommentText('');
      fetchComments();
    })
    .catch(error => {
      setFeedback({ 
        show: true, 
        message: 'Fehler beim Senden des Kommentars', 
        success: false 
      });
    });
  };

  const fetchComments = () => {
    if (serviceInfo && serviceInfo.Titel) {
      axios.get(`https://disapi.armann-systems.com/comments`, {
        params: { dienstTitel: serviceInfo.Titel, page: currentPage, limit: 10 }
      })
      .then(response => {
        if (response.data && response.data.comments) {
          setComments(response.data.comments);
          setTotalPages(response.data.totalPages);
        }
      })
      .catch(error => {
        console.error('Fehler beim Laden der Kommentare:', error);
      });
    }
  };

  useEffect(() => {
    fetchComments();
    const intervalId = setInterval(fetchComments, 30000);
    return () => clearInterval(intervalId);
  }, [serviceInfo, currentPage]);

  const reportIssue = () => {
    axios.post(`https://disapi.armann-systems.com/report`, { dienstTitel: dienstName })
      .then(() => {
        setFeedback({ show: true, message: 'Störung erfolgreich gemeldet', success: true });
        if (serviceInfo) {
          fetchReportData(serviceInfo.DienstID, timeRange);
        }
      })
      .catch(error => {
        setFeedback({ 
          show: true, 
          message: error.response?.data || 'Fehler beim Melden der Störung', 
          success: false 
        });
      });
  };

  return (
    <div className="issues-container">
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <link rel="canonical" href={`https://xn--strung-xxa.armann-systems.com/services/${dienstName}`} />
      </Helmet>

      <div className="sidebar left-sidebar">
        <RelatedServices />
      </div>

      <div className="main-content">
        <header className="issues-header">
          <img 
            src={`https://img.ashub.de/services/${serviceInfo ? serviceInfo.DienstName : ''}.png`} 
            alt={`${serviceInfo ? serviceInfo.Titel : dienstName} Logo`} 
            className="service-logo" 
            loading="lazy"
            onError={(e) => { e.target.onerror = null; e.target.src = 'https://img.ashub.de/services/404.png'; }}
          />
          <h1 className="service-title">{serviceInfo ? serviceInfo.Titel : dienstName}</h1>
          <p className="service-description">
            {serviceInfo ? serviceInfo.description : "Lade Dienstbeschreibung..."}
          </p>
        </header>

        <button className="report-issue-button" onClick={reportIssue}>
          Störung melden
        </button>

        <div className="chart-controls">
          <button 
            className={timeRange === '7days' ? 'active' : ''} 
            onClick={() => setTimeRange('7days')}
          >
            Letzte 7 Tage
          </button>
          <button 
            className={timeRange === '24hours' ? 'active' : ''} 
            onClick={() => setTimeRange('24hours')}
          >
            Letzte 24 Stunden
          </button>
        </div>
        
        <div className="chart-large">
          <Line data={chartData} options={chartOptions} />
        </div>

        {feedback.show && (
          <div className={`feedback-alert ${feedback.success ? 'success' : 'error'}`}>
            {feedback.message}
          </div>
        )}

        <button className="go-to-comments" onClick={() => {
          const commentsSection = document.querySelector('.comments-section');
          if (commentsSection) {
            commentsSection.scrollIntoView({ behavior: 'smooth' });
          }
        }}>
          <span>Zu den Kommentaren</span>
          <i className="arrow-down"></i>
        </button>

        <div className="comments-section">
          <h2>Kommentare</h2>
          <div className="comment-form">
            <input 
              type="text" 
              placeholder="Ihr Name" 
              className="comment-name-input"
              value={commentName}
              onChange={handleNameChange}
            />
            <textarea 
              placeholder="Ihr Kommentar" 
              className="comment-text-input"
              value={commentText}
              onChange={handleTextChange}
            />
            <button className="submit-comment-button" onClick={submitComment}>
              Kommentar absenden
            </button>
          </div>

          <div className="comment-list">
            {comments.map((comment, index) => (
              <div key={index} className="comment">
                <div className="comment-header">
                  <span>{comment.Name}</span>
                  <span>{new Date(comment.Timestamp).toLocaleString('de-DE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}</span>
                </div>
                <div className="comment-text">
                  {comment.Text}
                </div>
              </div>
            ))}
          </div>

          {totalPages > 1 && (
            <div className="comment-pagination">
              <button onClick={() => setCurrentPage(p => Math.max(1, p - 1))} disabled={currentPage === 1}>
                Vorherige
              </button>
              <span>{currentPage} von {totalPages}</span>
              <button onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))} disabled={currentPage === totalPages}>
                Nächste
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="sidebar right-sidebar">
        <RelatedServices />
      </div>
    </div>
  );
}

export default IssuesPage;
