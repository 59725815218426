import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './RelatedServices.css';

function RelatedServices() {
  const [relatedServices, setRelatedServices] = useState([]);

  useEffect(() => {
    axios.get('https://disapi.armann-systems.com/random-service?count=2')
      .then(response => {
        if (Array.isArray(response.data)) {
          setRelatedServices(response.data);
        } else {
          setRelatedServices([]);
        }
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der verwandten Dienste:', error);
        setRelatedServices([]);
      });
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength).trim() + '...';
  };

  return (
    <div className="related-services">
      <div className="related-services-grid">
        {relatedServices.map(service => (
          <div key={service.DienstID} className="related-service">
            <img 
              src={`https://img.ashub.de/services/${service.DienstName}.png`} 
              alt={`${service.Titel} Logo`} 
              onError={(e) => { e.target.onerror = null; e.target.src = 'https://img.ashub.de/services/404.png'; }} 
            />
            <h3>{service.Titel}</h3>
            <p>{truncateText(service.description, 150)}</p>
            <a href={`/services/${service.DienstName}`} className="rel-service-link">Ansehen</a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RelatedServices;
