import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import LandingPage from './LandingPage';
import ContactPage from './ContactPage';
import ImprintPage from './ImprintPage';
import NotFoundPage from './NotFoundPage';
import ServiceOverview from './ServiceOverview';
import Datenschutz from './Datenschutz';
import IssuesPage from './IssuesPage';
import FAQPage from './FAQPage';
import Logo from './logo100x55.png';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import './App.css';

function App() {
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleAccept = () => {
    setAcceptedCookies(true);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <div>
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-5PQ39Q8S7W"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-5PQ39Q8S7W');
            `}
          </script>
        </Helmet>
        <nav className="main-header-nav">
          <div className="main-header-container">
            <Link to="/" className="main-header-logo-link">
              <img src={Logo} alt="Logo" className="main-header-logo" />
            </Link>
            <button className="main-header-menu-btn" onClick={toggleMenu} aria-label="Menu">
              <span className={`main-header-menu-line ${isMenuOpen ? 'open' : ''}`}></span>
              <span className={`main-header-menu-line ${isMenuOpen ? 'open' : ''}`}></span>
              <span className={`main-header-menu-line ${isMenuOpen ? 'open' : ''}`}></span>
            </button>
            <div className={`main-header-nav-links ${isMenuOpen ? 'open' : ''}`}>
              <Link to="/" className="main-header-nav-item" onClick={() => setIsMenuOpen(false)}>Startseite</Link>
              <Link to="/services" className="main-header-nav-item" onClick={() => setIsMenuOpen(false)}>Dienste</Link>
              <Link to="/faq" className="main-header-nav-item" onClick={() => setIsMenuOpen(false)}>FAQ</Link>
              <Link to="/contact" className="main-header-legal-link" onClick={() => setIsMenuOpen(false)}>Kontakt</Link>
              <Link to="/imprint" className="main-header-legal-link" onClick={() => setIsMenuOpen(false)}>Impressum</Link>
              <Link to="/datenschutz" className="main-header-legal-link" onClick={() => setIsMenuOpen(false)}>Datenschutz</Link>
            </div>
          </div>
        </nav>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path='/services' element={<ServiceOverview />} />
          <Route path='/services/page/:page' element={<ServiceOverview />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/imprint" element={<ImprintPage />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/services/:dienstName" element={<IssuesPage />} />
          <Route path="/faq" element={<FAQPage />} />
        </Routes>

        {!acceptedCookies && (
          <CookieConsent
            location="bottom"
            buttonText="Akzeptieren"
            cookieName="cookieConsent"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            onAccept={handleAccept}
          >
            Durch die Nutzung dieser Website stimmen Sie unserer Datenschutzerklärung und der Verwendung von Cookies zur Sammlung von personenbezogenen Daten zu.
          </CookieConsent>
        )}
      </div>
      <footer className="main-footer">
        <p>&copy; 2024 Armann Systems GmbH. Alle Rechte vorbehalten.</p>
      </footer>
    </Router>
  );
}

export default App;
